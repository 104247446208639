.mobile_overlay_section {
	margin: 0;
    max-height: 160px;
	height: fit-content;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	z-index: 9900;
	transition: top 0.4s ease-in-out, height 0.4s ease-in-out;
}

.cover_background {
	margin: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--primary-color);
	z-index: 2;
	transition: bottom 0.4s ease-in-out, height 0.4s ease-in-out;
}

.sticky_contact_text_img {
	height: 4vh;
}

.footer_social_links {
	margin-right: 2vw;
	padding: 0.5vh 0.5vw;
}

.footer_social_image {
	padding: 0.2vh 0.2vw;
    height: 2vmax;
	max-height: 160px;
	width: auto;
	object-fit: scale-down;
	cursor: pointer;
}

.background_div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.background_div > ul {
	font-family: sans-serif;
	display: flex;
	flex-direction: column;
}

.navlink_list {
	margin: 2vh 0;
}

.navlink_list > a {
	text-decoration: none;
}

.navlink_list > a > h2 {
	text-align: center;
}

.active {
    background-color: aqua;
}

.only_mobile {
    display: none;
}
@media (max-width: 768px) {
	.mobile_overlay_section {
		top: 0;
	}

	.footer_social_links {
		padding-left: 0;
		margin: 3%;
		display: flex;
		justify-content: space-around;
	}
    
    .footer_social_image {
        height: 30px;
        padding: 0 0.4vmax;
    }

    .only_mobile {
        display: block;
        padding: 3vmax;     
    }
}

@media (min-width: 768px) {
	.background_div,
	.cover_background {
		display: none;
	}
}


