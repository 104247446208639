.recommending_background_div {
	width: 32vw;
	max-width: 100%;
	background-color: rgb(213, 213, 213);
	border-radius: 10%;
}

.recommending_div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.recommending_div:last-child {
    align-self: flex-end;
}

.recommending_v_img {
	width: 10vw;
	height: auto;
    position: relative;
	top: -10px;
	cursor: pointer;
}

.recommending_text {
    min-height: fit-content;
    min-height: none;
    max-height: none;
	margin: 1vw;
	color: var(--primary-black);
	max-width: 85%;
    
}

.recommending_name {
	margin-top: 1vw;
	text-align: center;
	color: var(--primary-white);
}

.recommending_profile {
	width: 10vw;
    max-width: 24vw;
    height: auto;
	border-radius: 50%;
	position: relative;
	top: 1vh;
	cursor: pointer;
}

@media (max-width: 768px) {
	.recommending_background_div {
		width: 100%;
		max-width: 100%;
	}

	.recommending_name {
        align-self: center;
	}

	.recommending_text {
        min-height: fit-content;
        min-height: none;
        max-height: none;
	}
    
	.recommending_v_img {
        width: 27vw;
        top: -.2vh;
	}
    
	.recommending_profile {
        width: 38vw;
        max-width: 40vw;
	}
}

@media (min-width: 2000px) {
	.recommending_background_div {
		width: 100%;
		max-width: 100%;
	}
}
