.recommendetions_section {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recommendetions_title {
    text-align: center;
    text-decoration: bold;
    color: var(--primary-black);
}

.recommendetions_title::after {
    content: "";
    padding: 0;
    width: 9vw;
    height: 4px;
    border-bottom: 0.3vw solid var(--primary-color);
    text-align: center;
    position: relative;
    display: block;
}

.recommendetions_container {
    margin-top: 4vh;
    margin-bottom: 8vh;
	display: flex;
}

.recommendetions_container img {
    cursor: default;
}

.recommendetions_container {
    gap: 1.2vw;
}

@media (max-width: 768px) {
    .recommendetions_container {
        flex-direction: column;
        justify-content: space-around;
        gap: 13vw;
	}
}
