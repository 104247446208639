.services_section {
	padding-top: 12vh;
	padding-bottom: 5vh;
	width: 100vw;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;

	background-image: url(../../../media/Background/1133pxX1162pxPinkishBackgroundPinkish.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-attachment: local;
	background-clip: border-box;
}

.services_title {
	margin: 2vh 0;
}

.services_title::after {
	content: "";
	display: block;
	width: 10vw;
	height: 4px;
	padding: 0;
	border-bottom: 0.3vw solid var(--primary-white);
}

.services_div {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
    padding: 0 2vw;
}

.services_div > div:nth-child(odd) {
	padding: 9vh 0;
}

.service_div {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.service_img {
	width: 25vw;
	height: auto;
	border-radius: 15%;
}

.service_description {
	word-wrap: normal;
	max-width: 80vmax;
}

.aligning_button {
	display: flex;
	justify-content: center;
}

@media (max-width: 768px) {
	.services_section {
		background-image: url(../../../media/Background/300pxX1214PinksihBackground.png);
	}

	.services_div > * {
		padding: 0;
	}

	.services_div {
		padding: 2vh 1vw;
		flex-direction: column;
	}

	.services_div > div:nth-child(odd) {
		padding: 0;
	}

	.service_div {
		padding: 4vh 0;
	}
    
	.services_title {
		text-align: center;
	}

	.service_title h2 {
		text-align: center;
	}

	.service_img {
		width: 60vw;
	}
}
