:root {
	/* VARIABLES */
	--primary-white: #fff;
	--primary-black: #000;
	--primary-blue: rgb(42, 160, 211);

	--primary-color: #f4c2c2; /* rgb(244, 194, 194); */
	--background-color: #f6dde1; /* rgba(246, 221, 225, 0.8) */

	/* IMAGE RELATED VARIABLES */
	--gallery-image-width: 25vw;
	/* TO maintain the gallery in a square, I choose vw on both option relative als seperate width and height */
	--gallery-image-height: 25vw;

	/* PREFIXES */
	/* Safari, Chrome */
	--webkit--primary-white: #fff;
	--webkit--primary-black: #000;
	--webkit--primary-color: rgb(244, 194, 194);

	/* Mozilla Firefox */
	--moz--primary-white: #fff;
	--moz--primary-black: #000;
	--moz--primary-color: rgb(244, 194, 194);

	/* Microsoft Edge */
	--ms--primary-white: #fff;
	--ms--primary-black: #000;
	--ms--primary-color: rgb(244, 194, 194);

	/* Opera */
	--o--primary-white: #fff;
	--o--primary-black: #000;
	--o--primary-color: rgb(244, 194, 194);

	/* For Future Use or Other Browsers */

	--x--primary-white: #fff;
	--x--primary-black: #000;
	--x--primary-color: rgb(244, 194, 194);
}
/* END PREFIXES */

/*  GLOBAL CSS CONTENT */
* {
	font-family: sans-serif;
	font-size: 120%;
	color: var(--primary-black);
    /* max-width: 100%; */
}

html {
	width: 100vw;
}


main {
	height: fit-content;
	color: var(--primary-color);
	width: 100vw;
}

figure {
	margin: 0;
	padding: 0;
	max-width: 100vw;
	max-height: 100vh;
}

img {
	cursor: zoom-in;
	object-fit: cover;
}

section {
	padding: 0.1vh 0.1vw;
	height: fit-content;
	width: 100vw;
	max-width: 100%;
}

a {
	cursor: pointer;
}

footer {
	width: 100vw;
}

/* TEXT */
h3 {
	/* font-size: 2vw; */
	font-weight: bold;
}

h2 {
	/* font-size: 1.5vw; */
	font-weight: bold;
}

p {
	/* font-size: 1.2vw; */
	line-height: 100%;
}

strong {
	display: inline-table;
	font-weight: bold;
}

button {
    cursor: pointer;
}

button::placeholder,
input,
input::placeholder,
textarea,
textarea::placeholder {
	font-size: 120%;
	line-height: 100%;
}

/* MEDIA AND RESPONSIVE */
@media (max-width: 300px) {
	h3 {
		font-size: 200%;
	}

	h2 {
		font-size: 190%;
	}

	p {
		font-size: 170%;
	}
}

@media (max-width: 768px) {
	header {
		display: none;
	}

	section {
		margin-top: 5vh;
	}

	h3 {
		font-size: 160%;
	}

	h2 {
		font-size: 130%;
	}

	p {
		font-size: 110%;
	}

	input,
	input::placeholder,
	textarea,
	textarea::placeholder {
		font-size: 110%;
	}
}

@media (min-width: 2000px) {
	.recommending_background_div {
		width: 100%;
		max-width: 100%;
	}

	h3 {
		font-size: 250%;
	}

	h2 {
		font-size: 200%;
	}

	p {
		font-size: 170%;
	}

	input,
	input::placeholder,
	textarea,
	textarea::placeholder {
		font-size: 270%;
	}
}
