.burger_div {
	display: none;
}

@media (max-width: 768px) {
	/* HAMBURGER CODE */
	.burger_div {
		margin: 1vw;
		width: 50px;
		height: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
	}

	.burger_div span {
		display: block;
		width: 35px;
		height: 3px;
		background: var(--primary-white);
		margin: 3px;
		border-radius: 5px;
		transition: 0.7s;
	}

	.burger_div_clicked {
		transform: rotate(360deg);
	}

	.burger_div_clicked span:nth-child(1) {
		transform: rotate(145deg) translateX(4px) translateY(6px);
		width: 16px;
	}

	.burger_div_clicked span:nth-child(2) {
		transform: rotate(90deg);
		width: 34px;
	}

	.burger_div_clicked span:nth-child(3) {
		transform: rotate(38deg) translateX(-7px) translateY(-20px);
		width: 16px;
	}

	/* DONE HAMBURGER CODE */
	.background_div {
		height: 100%;
		width: 100%;
		padding: 6vh 6vw;
		background-color: var(--primary-color);
		transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
	}

	.background_div img {
		background-color: aqua;
	}
    
	.background_div nav {
		display: flex;
		flex-direction: row;
		z-index: 9998;
		overflow: hidden;
		transition: height 1s ease-in-out;
	}

	.background_div nav {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.background_div nav a {
		color: var(--primary-black);
		margin: 1rem;
		text-decoration: none;
		transition: color 0.3s;
	}

	.background_div nav a:hover {
		color: var(--primary-color);
	}
}
