.main_footer {
	font-family: sans-serif;
	transition: transform 0.5s;
	border-top: 0.2vw solid var(--primary-black);
}

.footer_center_div {
	padding: 2vh 0;
	max-height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	background-color: var(--primary-black);
}

.footer_center_div div {
	padding: 2vh 1vw;
}

.footer_center_div div p {
    color: var(--primary-white);
	text-align: start;
	padding: 1.2vh 0;
	text-decoration: underline;
	white-space: nowrap;
}

.footer_sitemap {
	padding: 0 3vw;
}

/* Related the logo and the Google Maps image  */
.footer_images_div {
	width: 100vw;
	display: flex;
	justify-content: center;
	background-color: var(--primary-black);
}

.footer_center_logo {
	width: 24vw;
	height: auto;
	object-fit: scale-down;
}

.footer_hidden {
	display: none;
}

.copyright_footer {
    padding-top: 2vh;
    padding-bottom: 8vh;
    text-align: center;
}

.active {
    background-color: aqua;
}
@media (max-width: 516px) {
	.footer_center_div {
		flex-direction: column;
		align-items: center;
	}

	.footer_center_div div {
		padding: 0;
	}

	.footer_center_div div p {
		text-align: center;
	}
}

@media (max-width: 768px) {
	.footer_center_div div {
		padding: 0;
	}

	.footer_center_div p {
		padding: 3%;
		text-align: center;
	}
}