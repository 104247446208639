.unseted_img {
    max-width: 100%;
}

img {
    max-height: 80vh;
    object-fit: contain;
}

.lightbox {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	z-index: 1;

	/* Align img 2 center */
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 1;
	transition: opacity 1s;

	cursor: zoom-in;
}

/* Make the picture appear on the screen */
.show {
	visibility: visible;
	opacity: 1;
	cursor: zoom-out;
}

.show_image {
	width: 75vw;
	height: auto;
	cursor: zoom-out;
}

.show > img {
    max-height: 80vh;
    object-fit: contain;
} 

