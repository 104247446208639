header {
	position: sticky;
	top: 0;
	height: fit-content;
	z-index: 9999;
	background-color: var(--primary-white);
	overflow: hidden;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.gone_div {
    display: none;
}

.header:hover .gone_div {
    display: block;
}
/* END OBLIGATORY */

nav {
    width: 100%;
}

.logo_div {
    display: flex;
	align-items: center;
}

.gone_div img,
.logo_div img {
    height: 6vh;
    cursor: default;
}

.nav_ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: linear-gradient(to bottom, var(--primary-white), var(--primary-color));
}

.nav_ul li {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.nav_ul a {
    padding: 1vh 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.nav_ul a:hover {
    text-decoration: underline;
    
}

.active {
    background: linear-gradient(to bottom, var(--primary-white) 100%, var(--primary-color) 80%);
}

.nav_ul a li p {
    color: var(--primary-black);
	text-decoration: none;
    font-family: sans-serif;
}

/* LANGUAGE CHANGER REALTED */
.nav_language {
    min-width: fit-content;
    max-width: 7vmax;
    align-items: center;
    justify-content: center;
    display: flex;
}

.nav_language:hover {
    border: 1px solid var(--primary-black);
}
@media (max-width: 768px) {
    .header {
        display: none;
    }
}
