.main_component_div {
	display: inline-block;
	flex-direction: column;
}

.underlined_title::after {
	content: "";
	display: block;
	width: 4vw;
	height: 4px;
	padding: 0;
	border-bottom: 0.3vw solid var(--primary-color);
}


/* Classes for different colors and styles */
/* White Underline */
.white_underline .underlined_title::after {
	border-bottom: 0.3vw solid var(--primary-white);
}

/* Black Underline */
.black_underline .underlined_title::after {
	border-bottom: 0.3vw solid black; /* Or replace with your desired color for black underline */
}

/* White Text */
.white_text {
	color: var(--primary-white);
}

/* Black Text */
.black_text {
	color: black; /* Or replace with your desired color for black text */
}
