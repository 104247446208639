.instagram_section {
	padding-top: 3vh;
	color: var(--primary-black);
	display: flex;
	flex-direction: column;
	text-align: center;
	background-image: url(../../../media/Background/FromBottomPinkish.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
}

.instagram_section div {
	padding-bottom: 2vh;
	display: flex;
	flex-direction: row;
	gap: 1vw;
	align-items: center;
	justify-content: center;
}

.instagram_section h3::after {
	content: "";
	display: block;
	width: 3vw;
	height: 4px;
	padding: 0;
	border-bottom: 0.3vw solid var(--primary-color);
}

.instagram_logo {
	height: 7vh;
	max-width: 55vw;
	cursor: text;
}

.instagram_post_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 3rem;
}

.instagram_section a {
	color: pink;
}

.instagram_item {
    margin-top: 2vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: var(--primary-white);
	border: 0.3vw solid var(--primary-white);
}

.instagram_item img{
    cursor: pointer;
}

.instagram_item img {
	width: fit-content;
	max-width: 80vw;
}

#alighning_button_to_the_center_without_css_to_fix_the_problem_div {
    display: flex;
    align-items: center;
    justify-content: center;
}