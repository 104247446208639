.treatments_section {
	padding-top: 17vh;
	padding-bottom: 10vh;
	width: 100vw;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;

	background-image: url(../../../media/Background/168pxX1214pxPinkishBackground.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-attachment: local;
	background-clip: border-box;
}

.treatments_container figure:nth-child(5) h2::after {
	width: 2.2vw;
}

.treatments_title::after {
	width: 6vw;
	height: 4px;
	content: "";
	display: block;
	padding: 0;
	border-bottom: 0.3vw solid var(--primary-white);
}

.treatments_container {
	margin-top: 4vh;
	margin-bottom: 8vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4vw;
}

.treatment_div {
	padding-top: 1vh;
	width: 24vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
}

.treatment_img {
	max-width: 23vw;
	height: auto;
}

.treatment_text_div {
	padding-left: 3vw;
	margin-right: 2vw;
	width: inherit;
	display: flex;
	flex-direction: column;
}


.treatment_title {
	color: var(--primary-black);
	padding-top: 4vh;
}

.treatment_title::after {
	content: "";
	display: block;
	width: 60px;
	height: 2px;
	padding: 0;
	border-bottom: 3px solid var(--primary-color);
}

.treatment_description {
	padding: 2vh 3vw 3vh 3vw;
	width: 20vw;
	color: var(--primary-black);
}

@media (max-width: 768px) {
	.treatments_section {
		margin-top: 7vh;
		background-image: url(../../../media/Background/300pxX1214PinksihBackground.png);
	}

	.treatments_container {
		flex-direction: column;
	}

	.treatment_div {
		width: 98%;
		flex-direction: row-reverse;
	}

	.treatment_img {
		max-width: 40vw;
	}

	.treatment_text_div {
		margin-right: 0;
	}

	.treatment_title {
		padding-top: 1vh;
		padding-left: 3vw;
	}

	.treatment_description {
		width: 34vw;
		max-width: fit-content;
	}
}

@media (max-width: 371px) {
	.treatment_title {
		position: relative;
		left: 40%;
	}
}
