.products_section {
    padding: 0;
    display: flex;
	flex-direction: row;
    justify-content: space-around;
}

.products_section img {
    width: 40vw;
}

/* PRODUCTS TEXT -> Descriptions and text*/
.products_div {
	gap: 2vw;
	padding: 2vw;
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: center;
}

.products_title {
	gap: 2vw;
	padding: 2vw;
}

.products_title::after {
	content: "";
	display: block;
	width: 6vw;
    height: 4px;
	padding: 0;
	border-bottom: 0.3vw solid var(--primary-color);
}

.products_description {
	gap: 2vw;
	padding: 2vw;
}

/* MOBILE IMAGE */
.products_section div img {
    display: none;
}

@media (max-width: 768px) {  
    /* SCREEN IMAGE */
    .products_section img {
        display: none;
    }

    .products_section {
        flex-direction: column;
    }
    
    .products_section div img {
        display: block;
    }
    
    .products_section img {
        width: auto;
        max-width: 70vw;
    }
} 

