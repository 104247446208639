.button_link {
	margin: 0 2vh 6vh 2vh;
    cursor: pointer;
}

.round_button {
    padding: 10px 20px;
	max-width: fit-content;
	border: 2px solid var(--primary-color);
	border-radius: 23vw; 
	cursor: pointer;
}

.button_p {
    text-align: center;
}

@media (max-width: 768px) {
    .round_button {
        width: auto;
        max-width: fit-content;
    }
}

.round_button:hover {
	animation: rotateAnimation 1s ease-in-out;
}

@keyframes rotateAnimation {
    0% {
		transform: rotate(0deg);
	}
	15% {
        transform: rotate(-20deg);
	}
	40% {
        transform: rotate(15deg);
	}
	60% {
        transform: rotate(-10deg);
	}
	80% {
        transform: rotate(7deg);
	}
	100% {
        transform: rotate(0deg);
	}
}

.round_button:active {
    cursor: pointer;
	font-weight: bold;
	background-color: var(--primary-color);
}

.button_link {
	text-decoration: none;
}

/* WHITE BUTTON */
.white_round_button {
    border-color: 2px solid var(--primary-white);
	background-color: var(--primary-color);
}

.white_round_button:active {
	border-color: 2px solid var(--primary-color);
	font-weight: bold;
	background-color: var(--primary-white);
	color: var(--primary-color);
}