.engagment_section {
	padding-top: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.engagment_title {
	padding: 3vw 5vh;
	margin-bottom: 3vh;
	color: var(--primary-black);
	border: 0.2vw solid var(--primary-black);
}

.engagment_div {
    max-width: 90vw;
}

.engagment_description {
	width: 100%;
	padding-bottom: 10vh;
	color: var(--primary-black);
	line-height: 2.4vw;
}

.image_button_div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 4vw;
	align-items: center;
}

.engagment_img {
	width: 24vw;
	object-fit: scale-down;
	grid-column: 1;
	cursor: default;
	animation: fadeInOut 5s infinite;
}

/* ANIMATION */
@keyframes fadeInOut {
	0%, 100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
/* END ANIMATION */

/* RESPONSIVE */
@media (max-width: 768px) {
    .engagment_title {
        text-align: center;
	}
    
	.image_button_div {
		flex-direction: column;
	}
    
	.image_button_div {
        padding: 4vh 4vw;
	}
    
	.engagment_img {
        width: 100%;
		object-fit: cover;
	}
    
	.engagment_description {
        line-height: 5.4vh;
	}
}
@media (max-width: 768px) {
    .engagment_div br {
        display: block; /* Ensures that <br> is treated as a block element */
        content: ""; /* Add an empty content */
        margin-top: 3vh; /* Adjust the top margin to control the spacing */
    }
    
    .engagment_description {
        line-height: 130%;
    }
}
/* END RESPONSIVE */
